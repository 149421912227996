<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Reclamaciones')}} </h3>
		</div>
		
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="claim" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
					:paginator="true" :rows="25" >
                    <template #header>
                        <div class="table-header">
                            <div>
                                {{$t('Fecha')+'s'}}:
								<InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
								<InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
                            </div>
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <!--<Dropdown v-model="opcion2" :options="opciones2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar2()" v-if="opcion"></Dropdown>-->
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
					<Column field="id" :header="$t('ID')+' #'" headerStyle="width: 100px">
                        <template #body="slotProps">
                            {{slotProps.data.id}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
					<!--
                    <Column field="id" :header="$t('Nro')" headerStyle="width: 100px">
                        <template #body="slotProps">
							{{ slotProps.index }}                             
                        </template>
                    </Column>
					-->
                    <Column field="nombre" :header="$t('Titular de la póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                        
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                       
                        </template>
                    </Column>
                    <Column field="veh_placa" :header="$t('Placa N°')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.veh_placa}}
                        </template>
                    </Column>
                    <Column field="benef_nombre" :header="$t('Contraparte')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.benef_nombre}}
                        </template>
                    </Column>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="estimacion" :header="$t('Monto de Reserva')"  headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.estimacion)}}                              
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pagar')" headerStyle="width: 180px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.to_pay)}}
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pago')" headerStyle="width: 180px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.benef_pagado">
								{{moneda(slotProps.data.benef_total)}}
							</div>
							<div v-else>
								{{moneda(0)}}
							</div>
                        </template>
                    </Column>
                    <Column field="observacion" :header="$t('Comentario')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.observacion}}
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
						<div class="p-grid p-align-center vertical-container">
							<div class="p-col-11">
								{{$t('Reserva')}}: {{moneda(reserva)}} <br> 
								{{$t('Pagar')}}: {{moneda(totalbenf1)}} <br> 
								{{$t('Pago')}}: {{moneda(totalbenf2)}} <br> 
							</div>
							<div class="p-col-1">
								<Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="claim.length>0"/>
							</div>
						</div>
                    </template>
                </DataTable>
			</div>
        </div>

	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				fecha1: null,
				fecha2: null,
				opcion: null,
				opciones: [],
				opcion2: {code: 0, nombre: 'Todos', name: 'All'},
				opciones2: [
					{code: 0, nombre: 'Todos', name: 'All'},
				],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				seleccion: {},
				validationErrors: {},
				claim: [],
				claims: [],
				pdf: null,
				lpdf: null,
				total: 0,
				reserva: 0,
				totalbenf1: 0,
				totalbenf2: 0
			}
		},
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			const Consulta = new API('Reportes');
			this.fecha1 = Consulta.fstring(this.$store.state.fecha);
			this.fecha2 = Consulta.fstring(this.$store.state.fecha);
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				const Consulta = new API('Reportes');
				Consulta.Ini('Report3').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (result.consult.opciones){
						this.opciones = result.consult.opciones;
					}
					this.fecha1 = Consulta.fstring(result.consult.fecha);
					this.$store.commit('Loading');
				}); 
			},
			Limpiar(){
				this.claim = [];
				this.claims = [];	
				this.opcion = null;	
				this.opcion2 = {code: 0, nombre: 'Todos', name: 'All'};		
			},
			Buscar(){
				if(this.opcion){
					this.$store.commit('Loading');
					this.total = 0;
					this.reserva = 0;
					this.claim = [];
					this.claims = [];
					this.opcion2 = {code: 0, nombre: 'Todos', name: 'All'};
					this.opciones2 = [{code: 0, nombre: 'Todos', name: 'All'}];
					const Consulta = new API('Reportes');
					Consulta.Procesar('Report3',{
						caso: 	'Buscar',
						opcion: this.opcion.code,
						fecha1: Consulta.fdate(this.fecha1),
						fecha2: Consulta.fdate(this.fecha2),
						}).then(response => {
							//this.$store.state.error = response.result.claims[0];	
							if (response.result.claims){
								this.claim = response.result.claims;
								this.claims = response.result.claims;
							}
							if (response.result.opciones2){
								response.result.opciones2.forEach(result => {
									this.opciones2.push(result);
								});
							}
							this.total = response.result.total;
							this.reserva = response.result.reserva;
							this.totalbenf1 = response.result.totalbenf1;
							this.totalbenf2 = response.result.totalbenf2;
							this.$store.commit('Loading');
						});
				}
			},
			Buscar2(){
				this.$store.commit('Loading');
				this.claim = [];
				if(this.opcion2.code>0){
					this.claim = this.claims.filter(val => val.estado == this.opcion2.code);
				} else {
					this.claim = this.claims;
				}
				this.$store.commit('Loading');
			},

			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerXLS(){
				this.$store.commit('Loading');
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report3',{
					caso: 	'Ver',
					opcion: this.opcion.code,
					fecha1: Consulta.fdate(this.fecha1),
					fecha2: Consulta.fdate(this.fecha2),
					}).then(response => {
						const xls = new XLS();
						xls.Reporte3(response.result.claims,this.$store.state.currency,this.reserva,this.totalbenf1,this.totalbenf2);
						this.$store.commit('Loading');
					});
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			myUploader1(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report3',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){					
						this.informacion.lesionespdf = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader2(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report3',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){					
						this.informacion.aceptacion = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
